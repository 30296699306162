// @flow

import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";

import { Site, RouterContextProvider } from "tabler-react";

import type { NotificationProps } from "tabler-react";

type Props = {|
  +children: React.Node,
|};

type State = {|
  notificationsObjects: Array<NotificationProps>,
|};

type subNavItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +LinkComponent?: React.ElementType,
|};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +active?: boolean,
  +LinkComponent?: React.ElementType,
  +subItems?: Array<subNavItem>,
  +useExact?: boolean,
|};

const navBarItems: Array<navItem> = [
  {
    value: "Home",
    to: "/",
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "Twitter Feed",
    to: "/twitter-feed",
    icon: "check-square",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "About",
    to: "/about",
    LinkComponent: withRouter(NavLink),
  },
];

class SiteWrapper extends React.Component<Props, State> {
  state = {};

  render(): React.Node {
    return (
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "Dalit Every Second",
          imageURL: "./demo/DES_logo.png",
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          links: [
            "Links",
            <a href="https://bostonstudygroup.com">Boston Study Group</a>,
            <a href="https://roundtableindia.co.in">Roundtable India</a>,
            <a href="https://idsn.org/">International Dalit Solidarity Network</a>,
            <a href="https://equalitylabs.org">Equality Labs</a>,
            <a href="https://dalitnation.com">Dalit Nation</a>,
          ],
          note: "Visualizing Caste Discrimination and Airing Dalit Voices",
          copyright: (
            <React.Fragment>
              Copyright © 2019 Sean Yu and Alex Cui.
            </React.Fragment>
          ),
        }}
      >
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default SiteWrapper;
